import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SIDEBAR_ITEM } from './types';

const useSidebar = () => {
  const navigate = useNavigate();

  const [openMenus, setOpenMenus] = useState([]);
  const [setSelectedMenu, setSetSelectedMenu] = useState<string>('');

  const toggleMenu = (id: string) => {
    setOpenMenus((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  const onMenuClick = (menu: SIDEBAR_ITEM) => {
    if (menu.submenus) {
      toggleMenu(menu.id);
      return;
    }
    if (menu.path) {
      setSetSelectedMenu(menu.id);
      navigate(menu.path);
    }
  };

  return {
    openMenus,
    onMenuClick,
    setSelectedMenu,
    setSetSelectedMenu,
  };
};

export default useSidebar;
