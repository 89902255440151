import { useMemo } from 'react';
import { TDocumentTypeListResponse } from '~/config/types';
import { useUserAuth } from '~/shell/components/Auth/AuthProvider';
import ApiService from '../../api';
import { QUERY_KEYS } from './constants';
import { QueryDetails } from './types';

const {
  ACCOUNT_TYPE,
  USER_ACCOUNT,
  GET_DOC_TYPES_LIST,
  GET_WALLET_BALANCE,
  GET_ALL_USER_ACCOUNTS,
  USER_TYPE,
  GET_BANK_ACCOUNT,
} = QUERY_KEYS;

export const useQueryFactory = <TData, TError = Error>() => {
  const { accountTypeList } = useUserAuth();
  const key = accountTypeList?.data?.filter(
    (item: any) => item.name === 'Wallet',
  )?.[0]?.id;

  const queryDetails = useMemo<QueryDetails<TData, TError>>(
    () => ({
      [ACCOUNT_TYPE]: () => ({
        queryKey: [ACCOUNT_TYPE],
        queryFn: () => ApiService.getAccountTypes<TData>(),
      }),
      [USER_ACCOUNT]: () => ({
        queryKey: [USER_ACCOUNT],
        queryFn: () => ApiService.getUserAccount<TData>(key),
      }),
      [GET_DOC_TYPES_LIST]: () => ({
        queryKey: [GET_DOC_TYPES_LIST],
        queryFn: () => ApiService.getDocTypesList<TDocumentTypeListResponse>(),
      }),
      [GET_WALLET_BALANCE]: () => ({
        queryKey: [GET_WALLET_BALANCE],
        queryFn: () => ApiService.getWalletBalance<TData>(),
      }),
      [GET_ALL_USER_ACCOUNTS]: () => ({
        queryKey: [GET_ALL_USER_ACCOUNTS],
        queryFn: () => ApiService.getUserAllAccounts<TData>(),
      }),
      [USER_TYPE]: () => ({
        queryKey: [USER_TYPE],
        queryFn: () => ApiService.getUserTypeDetail<TData>(),
      }),
      [GET_BANK_ACCOUNT]: () => ({
        queryKey: [GET_BANK_ACCOUNT],
        queryFn: () => ApiService.getBankAccounts<TData>(),
      }),
    }),
    [],
  );

  return queryDetails;
};
