import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { theme } from '~/shell/theme/theme';

const DynamicBreadcrumbs = ({ routes }: { routes: any[] }) => {
  const location = useLocation();
  const pathnames = location.pathname;

  const findRoute = (pathname: string) =>
    routes.find((route) => {
      if (route.path === pathname) return true;

      if (route.path.includes(':')) {
        const routeRegex = new RegExp(
          `^${route.path.replace(/:\w+/g, '\\d+')}$`,
        );
        return routeRegex.test(pathname);
      }
      return false;
    });

  const route = findRoute(pathnames);

  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      separator={<ChevronRightIcon sx={{ fontSize: '1.2em' }} />}
    >
      <Link
        to='/'
        style={{
          fontSize: '14px',
          textDecoration: 'none',
          color: route?.breadcrumb
            ? theme.palette.secondary.light
            : theme.palette.secondary.main,
        }}
      >
        <HomeIcon sx={{ fontSize: '16px' }} />
      </Link>

      {route?.breadcrumb &&
        Array.isArray(route?.breadcrumb) &&
        route?.breadcrumb.map((item: any, i: number) =>
          route?.breadcrumb?.length - 1 === i ? (
            <Typography
              key={item.breadcrumb}
              sx={{ fontSize: '14px' }}
              color={theme.palette.secondary.main}
            >
              {item.breadcrumb}
            </Typography>
          ) : (
            <Link
              key={i}
              to={item?.path}
              style={{
                fontSize: '14px',
                color: theme.palette.secondary.light,
                textDecoration: 'none',
              }}
            >
              {item?.breadcrumb}
            </Link>
          ),
        )}

      {route?.breadcrumb && !Array.isArray(route?.breadcrumb) && (
        <Typography
          sx={{ fontSize: '14px' }}
          color={theme.palette.secondary.main}
        >
          {route.breadcrumb}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default DynamicBreadcrumbs;
