export const API_URLS = {
  ACCOUNT_TYPE: '/client/account-types',

  USER_ACCOUNT: '/user/accounts',

  LOCAL_CURRENCY: '/client/currency',

  BANK_ACCOUNT: '/client/bank_account_infos/approved',

  MT5_ACCOUNT: '/user/accounts',

  PAYMENT_METHODS: '/client/payment-methods',

  GET_DOC_TYPES_LIST: '/kyc-required-documents',

  GET_WALLET_BALANCE: '/user/wallets',

  GET_LEVERAGE: 'client/leverages',

  LIVE_MT_ACCOUNT: '/client/mt5-account-types/live',

  DEMO_MT_ACCOUNT: '/client/mt5-account-types/demo',

  GET_TRADING_ACCOUNTS_LIST: '/client/trading-accounts',

  TRANSFER_TYPE: '/client/transfer-types',

  NETWORK_TRANSFER_TYPE: (id: any) => `/client/accounts/${id}`,

  CHANGE_PASSWORD: '/change-password/user',

  CRYPTO_CURRENCY: 'client/crypto-currencies',

  GET_USER_TYPES: 'client/user-types',
};
