import CheckIcon from '@mui/icons-material/Check';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import TimelineIcon from '@mui/icons-material/Timeline';
import * as yup from 'yup';
import {
  AxisBankIcon,
  CryptoMethodIcon,
  DashboardDummyImage,
  LocalTransferIcon,
  PeskaMethodIcon,
  WirePaymentIcon,
} from '~/assets';
import messages from '~/modules/MyProfile/messages';
import { getNewPasswordSchema, getOldPasswordSchema } from '~/schema';

// we don't have status id
export const ACCOUNT_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  ACTIVE: 'Active',
} as const;

export const MODES = {
  VIEW: 'view',
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  CREATE: 'create',
  UPDATE: 'update',
  UPLOAD: 'upload',
  IS_FIRST_TIME: 'isFirstTime',
} as const;

export const COUNTRIES = {
  INDIA: 'India',
  //add as needed
};
export const DEFAULT_COUNTRY_CURRENCY = {
  INDIA: { name: 'INR', id: 4 },
  USD: { name: 'USD', id: 1 },
};
export const INPUT_FIELDS_LENGTHS = {
  FIRST_NAME: {
    MIN: 3,
    MAX: 100,
  },
  LAST_NAME: {
    MIN: 3,
    MAX: 100,
  },
  EMAIL: {
    MIN: 0,
    MAX: 255,
  },

  PHONE_NUMBER: {
    MIN: 8,
    MAX: 16,
  },
  REFERRAL: {
    MIN: 0,
    MAX: 255,
  },
  REMARK: {
    MIN: 8,
    MAX: 255,
  },
  PASSWORD: {
    MIN: 8,
    MAX: 20,
  },
  ADDRESS: {
    MIN: 3,
    MAX: 50,
  },
  IFSC: {
    MIN: 11,
    MAX: 11,
  },
  IBAN: {
    MIN: 8,
    MAX: 34,
  },
  ACCOUNT_NUMBER: {
    MIN: 6,
    MAX: 20,
  },
  BANK_NAME: {
    MIN: 3,
    MAX: 50,
  },
  CITY: {
    MIN: 3,
    MAX: 30,
  },
  STATE: {
    MIN: 2,
    MAX: 30,
  },
  POST_CODE: {
    MIN: 5,
    MAX: 30,
  },
  NATIONALITY: {
    MIN: 2,
    MAX: 30,
  },
  COMPANY_NAME: {
    MAX: 100,
  },
  COMPANY_ADDRESS_LINE_1: {
    MIN: 3,
    MAX: 150,
  },
  COMPANY_ADDRESS_LINE_2: {
    MIN: 3,
    MAX: 150,
  },
  COMPANY_LICENSE: {
    MIN: 3,
    MAX: 50,
  },
} as const;

export const INPUT_FIELD_REGEX = {
  EMAIL: /^[^\s][a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
  PHONE_NUMBER: /^\+?\d{1,4}\s?\d{1,14}$/,
  NAME: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
  IFSC: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  IBAN: /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/,
  // ADDRESS: /^[a-zA-Z0-9#' & * ^" ()|/\.,\-\[\]\s]*$/,
  ADDRESS: /^[a-zA-Z0-9 ,\\\-/#'\"().\[\]]*$/,

  REMARKS: /^[^\s].*[^\s]$/,

  USDTERC20_CRYPTO_ADDRESS: /^0x[a-fA-F0-9]{40}$/,
  USDTTRC20_CRYPTO_ADDRESS: /^T[a-zA-Z0-9]{33}$/,
  ETH_CRYPTO_ADDRESS: /^0x[a-fA-F0-9]{40}$/,
  BTC_CRYPTO_ADDRESS: /^(1|3|bc1)[a-zA-Z0-9]{25,39}$/,
} as const;

export const CRYPTO_CURRENCY_VALUES = {
  USDTERC20: 'USDTERC20',
  USDTTRC20: 'USDTTRC20',
  ETH: 'ETH',
  BTC: 'BTC',
};
export const REQUEST_STATUS = {
  ERROR: 'error',
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
} as const;

export const TRANSACTION_TYPES = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
  CREDIT: 'CREDIT',
  TRANSFER: 'TRANSFER',
};
export const VERIFY_OTP_STATUS = {
  SUCCESS: 1,
};
export const TRANSACTION_TYPES_KEYS = {
  MT5: 'MT5',
  WALLET: 'WALLET',
};

export const OTP_RESEND_INTERVAL_SECONDS = 120;

export const SEARCH_FILTERS = {
  TRANSACTION_TYPE: 'transaction_type',
  SEARCH: 'value',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

export const TRADING_ACCOUNT_TYPE = {
  LIVE: 'live',
  DEMO: 'demo',
};

export const CHANGE_LEVERAGE_TYPE = {
  LEVERAGE: 'LEVERAGE',
  PASSWORD: 'PASSWORD',
};
export const BANK_STATEMENT_DOCUMENT_DETAIL = {
  document_name: 'Bank Statement',
  id: 11,
};

export const SHOW_WALLET_VALUE = {
  WALLET: 'My Wallet',
  WALLET_KEY: 'WALLET',
};
export const PAYMENT_METHOD_KEYS = {
  LOCAL_TRANSFER: 'Local Transfer',
  WIRE_TRANSFER: 'Wire Transfer',
  ASIA_BANKS: 'Asia Banks',
  PESKA: 'Peska',
  CRYPTO: 'Crypto',
};

export const PAYMENT_METHOD_ICONS = {
  [PAYMENT_METHOD_KEYS.ASIA_BANKS]: AxisBankIcon,
  [PAYMENT_METHOD_KEYS.WIRE_TRANSFER]: WirePaymentIcon,
  [PAYMENT_METHOD_KEYS.LOCAL_TRANSFER]: LocalTransferIcon,
  [PAYMENT_METHOD_KEYS.PESKA]: PeskaMethodIcon,
  [PAYMENT_METHOD_KEYS.CRYPTO]: CryptoMethodIcon,
};

export const CHANGE_PASSWORD_FORM_VALUES = {
  new_password: '',
  confirm_password: '',
  old_password: '',
} as const;

export const getChangePasswordSchema = (formatMessage: any) =>
  yup.object().shape({
    old_password: getOldPasswordSchema(formatMessage),
    new_password: getNewPasswordSchema(formatMessage),
    confirm_password: yup
      .string()
      .oneOf(
        [yup.ref('new_password'), null],
        formatMessage(messages.passwordsMustMatch),
      )
      .required(formatMessage(messages.confirmPasswordRequired)),
  });

export const GET_KYC_UPLOAD_MODE = {
  EDIT: 'EDIT',
  ADD: 'ADD',
};

export const USER_TYPE = {
  Individual_Trader: 'Individual_Trader',
  Individual_IB: 'Individual_IB',
  Trader_Joint: 'Trader_Joint',
  Trader_Corporate: 'Trader_Corporate',
  IB_Joint: 'IB_Joint',
  IB_Corporate: 'IB_Corporate',
};

export const dummyImages = [
  {
    src: DashboardDummyImage,
  },
  {
    src: DashboardDummyImage,
  },
  {
    src: DashboardDummyImage,
  },
];

export const DashboardStepsIcons = {
  1: CheckIcon,
  2: CreditScoreIcon,
  3: HowToRegIcon,
  4: QueueIcon,
  5: TimelineIcon,
};

export const DEAL_TYPE = {
  OPEN: 'Open',
  CLOSE: 'Close',
};

export const MAX_BANK_ACCOUNT_LIMIT = 5;

export const ALLOWED_KEYS_FOR_NUMBER_INPUT = [
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Tab',
  'Delete',
];

export const MINIMUM_AGE_ALLOWED = 18;

export const TOAST_AUTO_HIDE_TIME = 5000;

export const UPLOADED_DOCUMENT_STATUS = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING: 'Pending',
  ACTIVE: 'Active',
} as const;

export const MIN_ALLOWED_TRADING_ACCOUNT_CREATION = 10;

export const ALLOWED_UPLOAD_FILE_TYPES = '.pdf,.jpg,.png,.heic,.jpeg';

export const ALLOWED_UPLOAD_FILE_SIZE = 4 * 1024 * 1024;

export const ALLOWED_UPLOAD_FILE_LISTS = [
  'application/pdf',
  'image/png',
  'image/jpg',
  'image/heic',
  'image/jpeg',
];

export const RESET_VALUE_TIMER = 10000;
