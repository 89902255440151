import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  firstNameRequired: {
    defaultMessage: 'Please enter your first name',
    id: 'schema.firstNameRequired',
  },
  firstNameTooLong: {
    defaultMessage: 'First name must be at most 100 characters',
    id: 'schema.firstNameTooLong',
  },
  firstNameMinLength: {
    defaultMessage: 'First name should be at least 3 characters',
    id: 'schema.firstNameMinLength',
  },
  lastNameRequired: {
    defaultMessage: 'Please enter your last name',
    id: 'schema.lastNameRequired',
  },
  lastNameMinLength: {
    defaultMessage: 'Last name should be at least 3 characters',
    id: 'schema.lastNameMinLength',
  },
  lastNameTooLong: {
    defaultMessage: 'Last name must be at most 100 characters',
    id: 'schema.lastNameTooLong',
  },
  emailRequired: {
    defaultMessage: 'Please provide your email address.',
    id: 'schema.emailRequired',
  },
  invalidEmail: {
    defaultMessage:
      'Please enter a valid email address (e.g., example@domain.com)',
    id: 'schema.invalidEmail',
  },
  emailTooLong: {
    defaultMessage: 'Email must be at most 255 characters',
    id: 'schema.emailTooLong',
  },
  peskaEmailRequired: {
    defaultMessage: 'Please enter your peska email address.',
    id: 'schema.peskaEmailRequired',
  },
  invalidPeskaEmail: {
    defaultMessage:
      'Please enter a valid peska email address (e.g., example@domain.com)',
    id: 'schema.invalidPeskaEmail',
  },
  noCountrySelected: {
    defaultMessage: 'Please select your country from the dropdown list',
    id: 'schema.noCountrySelected',
  },
  invalidPhoneNumber: {
    defaultMessage:
      'Phone number is required. Please enter a valid phone number',
    id: 'schema.invalidPhoneNumber',
  },
  phoneNumberTooLong: {
    defaultMessage: 'Phone number must be at most 16 digits',
    id: 'schema.phoneNumberTooLong',
  },
  phoneNumberMinLength: {
    defaultMessage: 'Phone number should be at least 8 digits',
    id: 'schema.phoneNumberMinLength',
  },
  phoneNumberRequired: {
    defaultMessage: 'Please enter a valid phone number ',
    id: 'schema.phoneNumberRequired',
  },
  referralRequired: {
    defaultMessage: 'Referral is required',
    id: 'schema.referralRequired',
  },
  referralTooLong: {
    defaultMessage: 'Referral must be at most 255 characters',
    id: 'schema.referralTooLong',
  },
  metaCommentRequired: {
    defaultMessage: 'Meta comment is required',
    id: 'schema.metaCommentRequired',
  },
  metaTooLong: {
    defaultMessage: 'Meta comment must be at most 255 characters',
    id: 'schema.metaTooLong',
  },
  addressTooLong: {
    defaultMessage: 'Address must be at most 100 characters .',
    id: 'schema.referralTooLong',
  },
  addressTooShort: {
    defaultMessage: 'Address must be at least 3 characters long.',
    id: 'schema.referralTooLong',
  },
  passwordRequired: {
    defaultMessage: 'Please provide your password.',
    id: 'schema.passwordRequired',
  },
  enterNewPassword: {
    defaultMessage: 'Please provide a new password',
    id: 'schema.enterNewPassword',
  },
  invalidPassword: {
    defaultMessage:
      'Your new password must be at least 8 characters long and include uppercase, lowercase, a number, and a special character',
    id: 'schema.invalidPassword',
  },
  loginInvalidPassword: {
    defaultMessage:
      'Your password must be at least 8 characters long and include uppercase, lowercase, a number, and a special character',
    id: 'schema.invalidPassword',
  },
  tncNotAgreed: {
    defaultMessage: 'Please click to agree the terms & conditions.',
    id: 'schema.tncNotAgreed',
  },
  fieldRequired: {
    defaultMessage: 'This field is required',
    id: 'schema.fieldRequired',
  },
  localCurrencyRequired: {
    defaultMessage: 'Please select a local currency.',
    id: 'schema.localCurrencyRequired',
  },
  cryptoCurrencyRequired: {
    defaultMessage: 'Please provide the crypto account address.',
    id: 'schema.cryptoCurrencyRequired',
  },
  mt5AccountRequired: {
    defaultMessage: 'Please select an MT5 account type.',
    id: 'schema.mt5AccountRequired',
  },
  paymentMethodRequired: {
    defaultMessage: 'Payment method is required',
    id: 'schema.paymentMethodRequired',
  },
  tradingAmountRequired: {
    defaultMessage: 'Trading amount is required',
    id: 'schema.tradingAmountRequired',
  },
  localAmountRequired: {
    defaultMessage: 'Please enter the amount to withdraw.',
    id: 'schema.localAmountRequired',
  },
  localTransferAmountRequired: {
    defaultMessage: 'Please enter the transfer amount.',
    id: 'schema.localTransferAmountRequired',
  },
  localAmountDepositRequired: {
    defaultMessage: 'Please enter the amount to deposit.',
    id: 'schema.localAmountDepositRequired',
  },
  localDepositAmountMin: {
    defaultMessage: 'The minimum deposit amount is 10 USD.',
    id: 'schema.localAmountMin',
  },
  localAmountMin: {
    defaultMessage: 'The minimum  amount is 10 USD.',
    id: 'schema.localAmountMin',
  },
  bankAccountRequired: {
    defaultMessage: 'Bank Account is required',
    id: 'schema.bankAccountRequired',
  },
  tradingCurrencyRequired: {
    defaultMessage: 'Trading currency is required',
    id: 'schema.tradingCurrencyRequired',
  },
  noteTooLong: {
    defaultMessage: 'Note must be at most 255 characters.',
    id: 'schema.noteTooLong',
  },
  noteTooShort: {
    defaultMessage: 'The note must have at least 8 characters.',
    id: 'schema.noteTooShort',
  },
  noteRequired: {
    defaultMessage: 'Please provide a note.',
    id: 'schema.noteRequired',
  },
  languageRequired: {
    defaultMessage: 'Language is required',
    id: 'schema.languageRequired',
  },
  addressLine1Required: {
    defaultMessage: 'Address Line 1 cannot be empty.',
    id: 'schema.addressLine1Required',
  },
  addressLine2Required: {
    defaultMessage: 'Address Line 2 cannot be empty.',
    id: 'schema.addressLine2Required',
  },
  cityTownRequired: {
    defaultMessage: 'Please enter your city or town',
    id: 'schema.cityTownRequired',
  },

  cityTooShort: {
    id: 'schema.cityTooShort',
    defaultMessage: 'City/Town name should be minimum 3 character',
  },
  cityTooLong: {
    id: 'schema.cityTooLong',
    defaultMessage: 'City/Town name must be at most 30 character',
  },
  stateProvinceRequired: {
    defaultMessage: 'Please enter your state or province.',
    id: 'schema.stateProvinceRequired',
  },
  stateTooShort: {
    id: 'schema.stateTooShort',
    defaultMessage: 'State name should be minimum 2 character',
  },
  stateTooLong: {
    id: 'schema.stateTooShort',
    defaultMessage: 'State name must be at most 30 character',
  },
  zipPostalCodeRequired: {
    defaultMessage: 'Please enter your zip or postal code.',
    id: 'schema.zipPostalCodeRequired',
  },
  pinCodeTooShort: {
    id: 'schema.pinCodeTooShort',
    defaultMessage: 'Zip/Postal code should be minimum 5 character',
  },
  pinCodeTooLong: {
    id: 'schema.pinCodeTooLong',
    defaultMessage: 'Zip/Postal code must be at most 30 character',
  },
  genderRequired: {
    defaultMessage: 'Please select your gender.',
    id: 'schema.genderRequired',
  },
  nationalityRequired: {
    defaultMessage: 'Please enter your nationality.',
    id: 'schema.nationalityRequired',
  },
  nationalityTooShort: {
    defaultMessage: 'Nationality should be minimum 2 character',
    id: 'schema.nationalityTooShort',
  },
  nationalityTooLong: {
    id: 'schema.nationalityTooLong',
    defaultMessage: 'Nationality must be at most 30 character',
  },
  dateOfBirthRequired: {
    defaultMessage: 'Please enter your date of birth.',
    id: 'schema.dateOfBirthRequired',
  },
  preferredLanguageRequired: {
    defaultMessage: 'Please select your preferred language.',
    id: 'schema.preferredLanguageRequired',
  },
  fullNameRequired: {
    defaultMessage: 'Full name is required',
    id: 'schema.fullNameRequired',
  },
  leverageRequired: {
    defaultMessage: 'Please select a leverage option.',
    id: 'schema.leverageRequired',
  },
  toAccountRequired: {
    id: 'schema.toAccountRequired',
    defaultMessage: 'Please select the destination account.',
  },
  fromAccountRequired: {
    id: 'schema.fromAccountRequired',
    defaultMessage: 'Please select the source account.',
  },
  compDateOfBirthRequired: {
    id: 'schema.compDateOfBirthRequired',
    defaultMessage: 'Company Start Date is required',
  },
  compNameRequired: {
    id: 'schema.compNameRequired',
    defaultMessage: 'Company Name is required',
  },
  compNameTooLong: {
    defaultMessage: 'Company Name must be at most 100 characters',
    id: 'schema.compNameTooLong',
  },
  compAddr1TooShort: {
    id: 'schema.compAddr1TooShort',
    defaultMessage: 'Company Address Line 1 should be minimum 3 character',
  },
  compAddr1TooLong: {
    id: 'schema.compAddr1TooLong',
    defaultMessage: 'Company Address Line 1 must be at most 50 characters',
  },
  compAddr2TooShort: {
    id: 'schema.compAddr2TooShort',
    defaultMessage: 'Company Address Line 2 should be minimum 3 character',
  },
  compAddr2TooLong: {
    id: 'schema.compAddr2TooLong',
    defaultMessage: 'Company Address Line 2 must be at most 50 characters',
  },
  companyLicenseTooShort: {
    id: 'schema.companyLicenseTooShort',
    defaultMessage: 'Company License should be minimum 3 character',
  },
  companyLicenseTooLong: {
    id: 'schema.companyLicenseTooLong',
    defaultMessage: 'Company License must be at most 50 characters',
  },
  companyLicenseRequired: {
    id: 'schema.companyLicenseRequired',
    defaultMessage: 'Company License is required',
  },
  transferTypeRequired: {
    id: 'schema.transferTypeRequired',
    defaultMessage: 'Please select a transfer type.',
  },
  cryptoAddRequired: {
    defaultMessage: 'Crypto Account address is required',
    id: 'schema.cryptoAddRequired',
  },
  oldPasswordRequired: {
    defaultMessage: 'Please enter your current password.',
    id: 'schema.passwordRequired',
  },
  invalidOldPassword: {
    defaultMessage:
      'The current password entered is incorrect. Please try again',
    id: 'schema.invalidPassword',
  },
  messageRequired: {
    defaultMessage: ' Message is required.',
    id: 'schema.messageRequired',
  },
  subjectRequired: {
    defaultMessage: ' Subject is required.',
    id: 'schema.subjectRequired',
  },
  invalidField: {
    id: 'schema.invalidField',
    defaultMessage:
      'This field cannot contain special characters or numbers. Please enter only alphabetic characters',
  },

  tradingAmountMin: {
    id: 'schema.tradingAmountMin',
    defaultMessage: 'Conversion amount should be at least 10 USD',
  },
  depositAmountMin: {
    id: 'schema.depositAmountMin',
    defaultMessage: 'Deposit amount should be at least 10 USD',
  },
  documentRequired: {
    id: 'bankAccounts.documentRequired',
    defaultMessage: 'Please upload the payment receipt.',
  },
  withdrawalAmountMin: {
    defaultMessage: ' Amount should be minimum 10 USD',
    id: 'schema.localAmountMin',
  },
  invalidDate: {
    defaultMessage: 'Please enter a valid date.',
    id: 'schema.invalidDate',
  },
  dobCantBeFuture: {
    id: 'schema.dobCantBeFuture',
    defaultMessage: 'Date of birth cannot be from the future.',
  },
  companyEstablishedCantBeFuture: {
    id: 'schema.companyEstablishedCantBeFuture',
    defaultMessage: 'Company established date cannot be from the future.',
  },
  addressLine2Invalid: {
    defaultMessage: 'Please enter valid address.',
    id: 'schema.addressLine2Required',
  },
  invalidAddress: {
    defaultMessage: `Please use only letters, numbers, spaces, and special characters like " , \ - / # ' \" ( ) . [ ].`,
    id: 'schema.invalidAddress',
  },
  ageMustBe18: {
    id: 'schema.ageMustBe18',
    defaultMessage: 'Please select 18 years or older to use this service.',
  },
  invalidNote: {
    id: 'schema.invalidNote',
    defaultMessage: 'Please enter valid note.',
  },

  usdTerc20FormatError: {
    id: 'schema.usdTerc20FormatError',
    defaultMessage:
      "Invalid USDT (ERC20) address. It should start with '0x' and be 42 characters long.",
  },
  usdTttrc20FormatError: {
    id: 'schema.usdTttrc20FormatError',
    defaultMessage:
      "Invalid USDT (TRC20) address. It should start with 'T' and be 34 characters long.",
  },
  ethFormatError: {
    id: 'schema.ethFormatError',
    defaultMessage:
      "Invalid Ethereum (ETH) address. It should start with '0x' and be 42 characters long.",
  },
  btcFormatError: {
    id: 'schema.btcFormatError',
    defaultMessage:
      "Invalid Bitcoin (BTC) address. It should start with '1', '3', or 'bc1' and be 26-35 characters long.",
  },
});
