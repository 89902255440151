import { createTheme } from '@mui/material';

// declare module '@mui/material/styles' {
//   interface Palette {
//     subtitle: Palette['primary'];
//   }

//   interface PaletteOptions {
//     subtitle?: PaletteOptions['primary'];
//   }
// }

// declare module '@mui/material/Typography' {
//   interface TypographyPropsColorOverrides {
//     subtitle: true;
//   }
// }

export const theme = createTheme({
  palette: {
    primary: {
      main: '#08345D',
      dark: '#051F38',
      light: '#1E88EC',
    },
    secondary: {
      main: '#F2940C',
      light: '#666B70',
      dark: '#484F60',
    },
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    error: {
      main: '#C1292A',
    },
    info: {
      main: '#596277',
      light: '#2196F3',
    },
    success: {
      main: '#4CAF50',
      dark: '#009900',
      light: '#2196F30A',
    },
    warning: {
      main: '#F2940C',
      light: '#BDCAEA',
      dark: '#D9EBFC',
    },
    text: {
      disabled: '#768098',
      primary: '#111317',
      secondary: '#23262F',
    },
  },
  spacing: 8,
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontSize: '96px',
      fontWeight: '300',
      lineHeight: '112.03px',
      letterSpacing: '-1.5px',
      textTransform: 'none',
    },
    h2: {
      fontSize: '60px',
      fontWeight: '300',
      lineHeight: '72px',
      letterSpacing: '-0.5px',
      textTransform: 'none',
    },
    h3: {
      fontSize: '48px',
      fontWeight: '400',
      lineHeight: '56.02px',
      textTransform: 'none',
    },
    h4: {
      fontSize: '34px',
      fontWeight: '400',
      lineHeight: '41.99px',
      letterSpacing: '0.25px',
      textTransform: 'none',
    },
    h5: {
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '32.02px',
      textTransform: 'none',
    },
    h6: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '32px',
      textTransform: 'none',
    },
    body1: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
    },

    subtitle1: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '28px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '22px',
    },
    overline: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '32px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '20px',
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#131F3D',
          color: '#D9EBFC',
          '&& :focus': {
            color: '#D9EBFC',
          },
          '&& :hover': {
            color: '#D9EBFC',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#ffffff',
          verticalAlign: 'top',
        },
        body: {
          color: '#000000',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#2196F314',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#BDCAEA',
        },
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#08345D',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#BDCAEA',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#969EB0', // Border color when disabled
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E27878', // Set your desired error color here
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSuccess: {
          backgroundColor: '#E5FFE5',
          color: '#2E7D32',
        },
        colorError: {
          backgroundColor: '#FBEAEA',
          color: '#D32F2F',
        },
      },
    },
  },
});
