import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';

import { useNavigate } from 'react-router-dom';
import {
  CHANGE_PASSWORD_FORM_VALUES,
  getChangePasswordSchema,
} from '~/config/constants';
import { useUserAuth } from '~/shell/components/Auth/AuthProvider';
import { onLogout } from '~/shell/components/Auth/config/utils';
import useMutationFactory from '../services/mutations/useMutationFactory';
import useToast from './useToast';

const useChangePassword = ({
  closeChangePasswordModal,
}: {
  closeChangePasswordModal: any;
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const { auth, setAuth } = useUserAuth();
  const { changePassword } = useMutationFactory();

  const { mutate, isPending } = useMutation<any, any, any>({
    ...changePassword(),
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { triggerSuccessToast, triggerErrorToast } = useToast();

  const onSuccess = (data: any) => {
    // setShowSuccessModal(true);
    triggerSuccessToast(data?.message);
    closeChangePasswordModal();
    onLogout(setAuth, navigate, queryClient);
  };

  const onError = (error: any) => {
    triggerErrorToast(error);
    // setShowSuccessModal(false);
  };
  const formik = useFormik({
    initialValues: CHANGE_PASSWORD_FORM_VALUES,

    onSubmit: (values) => {
      const payload = {
        ...values,
        user_id: auth?.id,
      };

      mutate(payload, {
        onSuccess: onSuccess,
        onError: onError,
      });
    },
    validationSchema: getChangePasswordSchema(formatMessage),
  });

  return {
    formik,
    isPending: isPending,
    // showSuccessModal,
    // onCloseSuccessModal,
  };
};
export default useChangePassword;
