export const breadCrumbRoutes = [
  { path: '/', breadcrumb: 'Dashboard' },
  { path: '/bank-accounts', breadcrumb: 'Bank Accounts' },
  { path: '/my-kyc', breadcrumb: 'My KYC' },
  { path: '/trading-accounts/list', breadcrumb: 'Trading Accounts' },
  {
    path: '/trading-accounts/:id',
    breadcrumb: [
      { path: '/trading-accounts/list', breadcrumb: 'Trading Accounts' },
      { path: '', breadcrumb: 'Trading Account' },
    ],
  },
  { path: '/wallet-details', breadcrumb: 'My Wallet' },
  { path: '/deposits/wallet', breadcrumb: 'Deposit-Wallet' },
  {
    path: '/deposits/wallet/add-deposit',
    breadcrumb: [
      { path: '/deposits/wallet', breadcrumb: 'Deposit-Wallet' },
      { path: '', breadcrumb: 'Request Deposit' },
    ],
  },
  { path: '/withdrawal/wallet', breadcrumb: 'Withdrawal-Wallet' },
  {
    path: '/withdrawal/wallet/request-withdrawal',
    breadcrumb: [
      { path: '/withdrawal/wallet', breadcrumb: 'Withdrawal-Wallet' },
      { path: '', breadcrumb: 'Request Withdrawal' },
    ],
  },
  { path: '/deposits/mt5', breadcrumb: 'Deposit-MT5' },
  {
    path: '/deposits/mt5/add-deposit',
    breadcrumb: [
      { path: '/deposits/mt5', breadcrumb: 'Deposit-MT5' },
      { path: '', breadcrumb: 'Request Deposit' },
    ],
  },
  { path: '/withdrawal/mt5', breadcrumb: 'Withdrawal-MT5' },
  {
    path: '/withdrawal/mt5/request-withdrawal',
    breadcrumb: [
      { path: '/withdrawal/mt5', breadcrumb: 'Withdrawal-MT5' },
      { path: '', breadcrumb: 'Request Withdrawal' },
    ],
  },
  { path: '/transfer', breadcrumb: 'Transfer' },

  {
    path: '/request-transfer',
    breadcrumb: [
      { path: '/transfer', breadcrumb: 'Transfer' },
      { path: '', breadcrumb: 'Request Transfer' },
    ],
  },
  { path: '/credit-history', breadcrumb: 'Credit' },
  { path: '/partnership/my-network', breadcrumb: 'My Network' },
  { path: '/partnership/performance', breadcrumb: 'My Network Performance' },
  { path: '/partnership/statement', breadcrumb: 'My Statement' },
  { path: '/partnership/referral', breadcrumb: 'My Referral' },
  { path: '/partnership/genealogy', breadcrumb: 'My Network Genealogy' },
  { path: '/my-profile', breadcrumb: 'My Profile' },
  { path: '/support', breadcrumb: 'Support' },
  { path: '/mt5-platform-links', breadcrumb: 'MT5 Platform Links' },
  { path: '/reports/trading', breadcrumb: 'Trading Statement' },
  { path: '/reports/deposit', breadcrumb: 'Deposit Statement' },
  { path: '/reports/withdrawal', breadcrumb: 'Withdrawal Statement' },
  { path: '/reports/transfer', breadcrumb: 'Transfer Statement' },

];
