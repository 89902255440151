import dayjs from 'dayjs';
import * as yup from 'yup';
import {
  CRYPTO_CURRENCY_VALUES,
  INPUT_FIELD_REGEX,
  INPUT_FIELDS_LENGTHS,
  MINIMUM_AGE_ALLOWED,
} from '../config/constants';
import messages from './validationMessages';

export const getFirstNameSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .max(
      INPUT_FIELDS_LENGTHS.FIRST_NAME.MAX,
      formatMessage(messages.firstNameTooLong),
    )
    .min(
      INPUT_FIELDS_LENGTHS.FIRST_NAME.MIN,
      formatMessage(messages.firstNameMinLength),
    )
    .matches(INPUT_FIELD_REGEX.NAME, formatMessage(messages.invalidField))
    .required(formatMessage(messages.firstNameRequired));

export const getLastNameSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .matches(INPUT_FIELD_REGEX.NAME, formatMessage(messages.invalidField))
    .min(
      INPUT_FIELDS_LENGTHS.LAST_NAME.MIN,
      formatMessage(messages.lastNameMinLength),
    )
    .max(
      INPUT_FIELDS_LENGTHS.LAST_NAME.MAX,
      formatMessage(messages.lastNameTooLong),
    )
    .required(formatMessage(messages.lastNameRequired));

export const getEmailSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(INPUT_FIELDS_LENGTHS.EMAIL.MAX, formatMessage(messages.emailTooLong))
    .email(formatMessage(messages.invalidEmail))
    .required(formatMessage(messages.emailRequired))
    .matches(INPUT_FIELD_REGEX.EMAIL, formatMessage(messages.invalidEmail));

export const getPeskaEmailSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(INPUT_FIELDS_LENGTHS.EMAIL.MAX, formatMessage(messages.emailTooLong))
    .email(formatMessage(messages.invalidPeskaEmail))
    .required(formatMessage(messages.peskaEmailRequired))
    .matches(
      INPUT_FIELD_REGEX.EMAIL,
      formatMessage(messages.invalidPeskaEmail),
    );

export const getCountrySchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.noCountrySelected));

export const getPhoneNumberSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(
      INPUT_FIELDS_LENGTHS.PHONE_NUMBER.MAX,
      formatMessage(messages.phoneNumberTooLong),
    )
    .min(
      INPUT_FIELDS_LENGTHS.PHONE_NUMBER.MIN,
      formatMessage(messages.phoneNumberMinLength),
    )
    .matches(
      INPUT_FIELD_REGEX.PHONE_NUMBER,
      formatMessage(messages.invalidPhoneNumber),
    )
    .required(formatMessage(messages.phoneNumberRequired));

export const getRefferalSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(
      INPUT_FIELDS_LENGTHS.REFERRAL.MAX,
      formatMessage(messages.referralTooLong),
    )
    .required(formatMessage(messages.referralRequired));
export const getMetaCommentSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(INPUT_FIELDS_LENGTHS.REFERRAL.MAX, formatMessage(messages.metaTooLong))
    .required(formatMessage(messages.metaCommentRequired));

export const getPasswordSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .matches(
      INPUT_FIELD_REGEX.PASSWORD,
      formatMessage(messages.invalidPassword),
    )
    .required(formatMessage(messages.passwordRequired));

export const getNewPasswordSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .matches(
      INPUT_FIELD_REGEX.PASSWORD,
      formatMessage(messages.invalidPassword),
    )
    .required(formatMessage(messages.enterNewPassword));

export const getOldPasswordSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .matches(
      INPUT_FIELD_REGEX.PASSWORD,
      formatMessage(messages.invalidOldPassword),
    )
    .required(formatMessage(messages.oldPasswordRequired));

export const getIsTncAggreedSchema = (formatMessage: (msg: any) => string) =>
  yup.boolean().oneOf([true], formatMessage(messages.tncNotAgreed));

export const getDropdownRequiredSchema = (
  formatMessage: (msg: any) => string,
  errorMessage: any,
) => yup.object().required(formatMessage(errorMessage));
export const getLocalCurrencySchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.localCurrencyRequired));

export const getCryptoCurrencySchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.cryptoCurrencyRequired));
export const getMT5AccountSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.mt5AccountRequired));
export const getPaymentMethodSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.paymentMethodRequired));
export const getLocalAmountSchema = (formatMessage: (msg: any) => string) =>
  yup
    .number()
    .required(formatMessage(messages.localTransferAmountRequired))
    .min(1, formatMessage(messages.localAmountMin));

export const getLocalAmountDepositSchema = (
  formatMessage: (msg: any) => string,
) =>
  yup
    .number()
    .required(formatMessage(messages.localAmountDepositRequired))
    .min(1, formatMessage(messages.localAmountMin));
export const getWithdrawalAmountSchema = (
  formatMessage: (msg: any) => string,
) =>
  yup
    .number()
    .required(formatMessage(messages.localAmountRequired))
    .min(10, formatMessage(messages.withdrawalAmountMin));
export const getBanAccountSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.bankAccountRequired));
export const getTradingCurrencySchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.tradingCurrencyRequired));

export const getTradingAmountSchema = (formatMessage: (msg: any) => string) =>
  yup.number().min(10, formatMessage(messages.tradingAmountMin));

export const getDepositAmountSchema = (formatMessage: (msg: any) => string) =>
  yup.number().min(10, formatMessage(messages.depositAmountMin));
export const getNoteSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .matches(INPUT_FIELD_REGEX.REMARKS, formatMessage(messages.invalidNote))
    .min(INPUT_FIELDS_LENGTHS.REMARK.MIN, formatMessage(messages.noteTooShort))
    .max(INPUT_FIELDS_LENGTHS.REMARK.MAX, formatMessage(messages.noteTooLong))
    .required(formatMessage(messages.noteRequired));

export const getUserDocumentSchema = (formatMessage: (msg: any) => any) =>
  yup.mixed().required(formatMessage(messages.documentRequired));
export const getCryptoAccAddressSchema = (
  formatMessage: (msg: any) => string,
  cryptoCurrencyValue?: any,
) => {
  if (cryptoCurrencyValue?.name === CRYPTO_CURRENCY_VALUES?.USDTTRC20) {
    return yup
      .string()
      .matches(
        INPUT_FIELD_REGEX.USDTTRC20_CRYPTO_ADDRESS,
        formatMessage(messages.usdTttrc20FormatError),
      )
      .required(formatMessage(messages.cryptoAddRequired));
  } else if (cryptoCurrencyValue?.name === CRYPTO_CURRENCY_VALUES?.ETH) {
    return yup
      .string()
      .matches(
        INPUT_FIELD_REGEX.ETH_CRYPTO_ADDRESS,
        formatMessage(messages.ethFormatError),
      )
      .required(formatMessage(messages.cryptoAddRequired));
  } else if (cryptoCurrencyValue?.name === CRYPTO_CURRENCY_VALUES?.BTC) {
    return yup
      .string()
      .matches(
        INPUT_FIELD_REGEX.BTC_CRYPTO_ADDRESS,
        formatMessage(messages.btcFormatError),
      )
      .required(formatMessage(messages.cryptoAddRequired));
  } else if (cryptoCurrencyValue?.name === CRYPTO_CURRENCY_VALUES?.USDTERC20) {
    return yup
      .string()
      .matches(
        INPUT_FIELD_REGEX.USDTERC20_CRYPTO_ADDRESS,
        formatMessage(messages.usdTerc20FormatError),
      )
      .required(formatMessage(messages.cryptoAddRequired));
  } else {
    return yup.string().required(formatMessage(messages.cryptoAddRequired));
  }
};

export const getPreferredLanguageSchema = (
  formatMessage: (msg: any) => string,
) => yup.object().required(formatMessage(messages.preferredLanguageRequired));

export const getAddressLine1Schema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .matches(INPUT_FIELD_REGEX.ADDRESS, formatMessage(messages.invalidAddress))

    .min(
      INPUT_FIELDS_LENGTHS.ADDRESS.MIN,
      formatMessage(messages.addressTooShort),
    )
    .max(
      INPUT_FIELDS_LENGTHS.ADDRESS.MAX,
      formatMessage(messages.addressTooLong),
    )
    .required(formatMessage(messages.addressLine1Required));

export const getAddressLine2Schema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .required(formatMessage(messages.addressLine2Required))
    .min(
      INPUT_FIELDS_LENGTHS.ADDRESS.MIN,
      formatMessage(messages.addressTooShort),
    )
    .matches(INPUT_FIELD_REGEX.ADDRESS, formatMessage(messages.invalidAddress))

    .max(
      INPUT_FIELDS_LENGTHS.ADDRESS.MAX,
      formatMessage(messages.addressTooLong),
    );

export const getCityTownSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .matches(INPUT_FIELD_REGEX.NAME, formatMessage(messages.invalidField))
    .min(INPUT_FIELDS_LENGTHS.CITY.MIN, formatMessage(messages.cityTooShort))
    .max(INPUT_FIELDS_LENGTHS.CITY.MAX, formatMessage(messages.cityTooLong))
    .required(formatMessage(messages.cityTownRequired));

export const getStateProvinceSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .required(formatMessage(messages.stateProvinceRequired))
    .min(INPUT_FIELDS_LENGTHS.STATE.MIN, formatMessage(messages.stateTooShort))
    .max(INPUT_FIELDS_LENGTHS.STATE.MAX, formatMessage(messages.stateTooLong))
    .matches(INPUT_FIELD_REGEX.NAME, formatMessage(messages.invalidField));

export const getZipPostalCodeSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .required(formatMessage(messages.zipPostalCodeRequired))
    .min(
      INPUT_FIELDS_LENGTHS.POST_CODE.MIN,
      formatMessage(messages.pinCodeTooShort),
    )

    .max(
      INPUT_FIELDS_LENGTHS.POST_CODE.MAX,
      formatMessage(messages.pinCodeTooLong),
    );

export const getDateOfBirthSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .required(formatMessage(messages.dateOfBirthRequired))
    .test(
      'is-valid-date',
      formatMessage(messages.invalidDate),
      (value) => !value || !isNaN(Date.parse(value)),
    )

    .test('age-check', formatMessage(messages.ageMustBe18), (value) => {
      if (!value) return true;

      const birthdate = dayjs(value);
      const eighteenYearsAgo = dayjs().subtract(MINIMUM_AGE_ALLOWED, 'years');

      const isBefore18 = birthdate.isAfter(
        eighteenYearsAgo.add(1, 'day'),
        'day',
      );

      return !isBefore18;
    })

    .test(
      'is-not-future-date',
      formatMessage(messages.dobCantBeFuture),
      (value) => !value || dayjs(value).isBefore(dayjs(), 'day'),
    );

export const getGenderSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.genderRequired));

export const getNationalitySchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .required(formatMessage(messages.nationalityRequired))
    .transform((value) => value.replace(/\s+/g, ''))
    .matches(INPUT_FIELD_REGEX.NAME, formatMessage(messages.invalidField))
    .min(
      INPUT_FIELDS_LENGTHS.NATIONALITY.MIN,
      formatMessage(messages.nationalityTooShort),
    )
    .max(
      INPUT_FIELDS_LENGTHS.NATIONALITY.MAX,
      formatMessage(messages.nationalityTooLong),
    );

export const getFullNameSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .required(formatMessage(messages.fullNameRequired));

export const getLeverageSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.leverageRequired));

export const getToAccountSchema = (formatMessage: (msg: any) => string) =>
  yup
    .mixed()
    .test(
      'is-object-or-string',
      formatMessage(messages.toAccountRequired),
      (value) =>
        (typeof value === 'object' || typeof value === 'number') &&
        value !== null,
    )
    .required(formatMessage(messages.toAccountRequired));

export const getFromAccountSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.fromAccountRequired));

export const getCompDateOfBirthSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .required(formatMessage(messages.compDateOfBirthRequired))
    .test(
      'is-valid-date',
      formatMessage(messages.invalidDate),
      (value) => !value || !isNaN(Date.parse(value)),
    )
    .test(
      'is-not-future-date',
      formatMessage(messages.companyEstablishedCantBeFuture),
      (value) => !value || dayjs(value).isBefore(dayjs(), 'day'),
    );

export const getCompanyNameSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .max(
      INPUT_FIELDS_LENGTHS.COMPANY_NAME.MAX,
      formatMessage(messages.compNameTooLong),
    )
    .required(formatMessage(messages.compNameRequired));

export const getSubjectSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .required(formatMessage(messages.subjectRequired))
    .transform((value) => value.replace(/\s+/g, ''));

export const getMessageSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.messageRequired));
export const getCompanyLicenseSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))

    .min(
      INPUT_FIELDS_LENGTHS.COMPANY_LICENSE.MIN,
      formatMessage(messages.companyLicenseTooShort),
    )
    .max(
      INPUT_FIELDS_LENGTHS.COMPANY_LICENSE.MAX,
      formatMessage(messages.companyLicenseTooLong),
    )
    .required(formatMessage(messages.companyLicenseRequired));
export const getTransferTypeSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.transferTypeRequired));

export const getLoginPasswordSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .matches(
      INPUT_FIELD_REGEX.PASSWORD,
      formatMessage(messages.loginInvalidPassword),
    )
    .required(formatMessage(messages.passwordRequired));
