import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import React from 'react';
import AsyncDropdown from 'v2s-ui-core/app/components/AsyncDropdown';
import Button from 'v2s-ui-core/app/components/Button';
import {
  FormattedMessage,
  useLocaleContext,
} from 'v2s-ui-core/app/Integrations/Localization';
import { API_URLS } from './api/config';
import messages from './messages';
import { useSubmitUserType } from './useSubmitUserType';

export const SelectUserTypeDialog = () => {
  const [value, setValue] = React.useState('');

  const {
    isUserTypeDialogOpen,
    onSubmitUserType,
    status: submitUserTypeStatus,
  } = useSubmitUserType();
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  return (
    <Dialog
      open={isUserTypeDialogOpen}
      PaperProps={{
        component: 'form',
        sx: {
          width: { md: '444px', xs: '343px' },
        },
      }}
    >
      <DialogTitle
        sx={{
          width: 'auto',
          display: 'flex',
          height: '48px',
          bgcolor: blue[50],
          p: 1,
        }}
      >
        <Typography textAlign={'center'} className='h6-card-dialog-title'>
          <FormattedMessage {...messages.selectUserType} />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box className='formContainer' mt={2} width={'80%'}>
          <AsyncDropdown
            // @ts-ignore
            value={value || null}
            onChange={(e, value) => setValue(value)}
            queryProps={{
              resource: API_URLS.GET_USER_TYPES,
              queryKeyProp: ['GET_USER_TYPES'],
              select: (data: any) => data.data,
            }}
            InputProps={{
              label: <FormattedMessage {...messages.selectUserType} />,
              required: true,
              name: 'user_type',
              placeholder: formatMessage(messages.select),
            }}
            getOptionLabel={(option) => option.type}
            getOptionKey={(option) => option.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: `1px solid ${blue[50]}`, height: '48px' }}
      >
        <Box display={'flex'} justifyContent={'right'}>
          <Button
            onClick={() => {
              onSubmitUserType(value);
            }}
            color='primary'
            variant='contained'
            size='medium'
            disabled={!value}
          >
            <FormattedMessage {...messages.submit} />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
