import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  pageNotFoundSubHeader: {
    id: 'pageNotFound.pageNotFoundSubHeader',
    defaultMessage: "Oops! The page you're looking for is under development.",
  },
  goToUserProfile: {
    id: 'pageNotFound.goToUserProfile',
    defaultMessage: 'Go to User Profile',
  },
  fileToLarge: {
    id: 'pageNotFound.fileToLarge',
    defaultMessage: 'Please choose a file that is 4 MB or smaller.',
  },
  fileFormatAllowed: {
    id: 'pageNotFound.fileFormatAllowed',
    defaultMessage:
      'Please choose only JPG, JPEG, PNG, and PDF files to upload.',
  },
});
