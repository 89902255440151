import { TOAST_AUTO_HIDE_TIME } from '~/config/constants';
import ToastNotificationsProvider from '~app/containers/ToastNotifications/Provider';
import RoutingProvider from '../../Routing';
import EntryPoint from '../EntryPoint';
import ServiceInterceptor from '../EntryPoint/ServiceInterceptor';
import UnauthorizedInterceptor from '../EntryPoint/UnauthorizedInterceptorWithStorage';

const Application: React.FC = () => (
  <EntryPoint>
    <ServiceInterceptor>
      <ToastNotificationsProvider autoHideDuration={TOAST_AUTO_HIDE_TIME}>
        <UnauthorizedInterceptor>
          <RoutingProvider />
        </UnauthorizedInterceptor>
      </ToastNotificationsProvider>
    </ServiceInterceptor>
  </EntryPoint>
);
export default Application;
