import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  changePassword: {
    id: 'userMenu.changePassword',
    defaultMessage: 'Change Password',
  },
  logout: {
    id: 'userMenu.logout',
    defaultMessage: 'Logout',
  },
  personalInformation: {
    id: 'userMenu.personalInformation',
    defaultMessage: 'Personal Information',
  },
  jointAccount: {
    id: 'userMenu.jointAccount',
    defaultMessage: 'Joint Account',
  },
  financialInformation: {
    id: 'userMenu.FinancialInformation',
    defaultMessage: 'Financial Information',
  },
  corporateInformation: {
    id: 'userMenu.corporateInformation',
    defaultMessage: 'Corporate Information',
  },
  successProfileUpdate: {
    id: 'userMenu.successProfileUpdate',
    defaultMessage: 'Your profile has been successfully updated',
  },
  successJointAccountUpdate: {
    id: 'userMenu.successJointAccountUpdate',
    defaultMessage: 'Your joint account has been successfully updated',
  },
  successChangePasswordUpdate: {
    id: 'userMenu.successChangePasswordUpdate',
    defaultMessage: 'Your password has been successfully updated',
  },
  oldPassword: {
    id: 'userMenu.oldPassword',
    defaultMessage: 'Enter Old Password',
  },
  newPassword: {
    id: 'userMenu.odPassword',
    defaultMessage: 'Enter New Password',
  },
  retypePassword: {
    id: 'userMenu.retypePassword',
    defaultMessage: 'Confirm New Password',
  },
  changePasswordBtn: {
    id: 'userMenu.changePasswordBtn',
    defaultMessage: 'Submit',
  },
  myProfile: {
    id: 'userMenu.myProfile',
    defaultMessage: 'My Profile',
  },
  dobTitle: {
    id: 'userMenu.dobTitle',
    defaultMessage: 'Date of Birth',
  },
  country: {
    id: 'userMenu.country',
    defaultMessage: 'Country',
  },
  language: {
    id: 'userMenu.language',
    defaultMessage: 'Language',
  },
  dateOfBirthLabel: {
    id: 'userMenu.dateOfBirthLabel',
    defaultMessage: 'Date of birth *',
  },
  educationLevelRequired: {
    id: 'userMenu.educationLevelRequired',
    defaultMessage: 'Please select your education level.',
  },
  tradingPurposeRequired: {
    id: 'userMenu.tradingPurposeRequired',
    defaultMessage:
      'Please specify the intended purpose and nature of trading.',
  },
  employmentStatusRequired: {
    id: 'userMenu.employmentStatusRequired',
    defaultMessage: 'Please select your employment status.',
  },
  estimatedNetWorthRequired: {
    id: 'userMenu.estimatedNetWorthRequired',
    defaultMessage: 'Please enter your estimated net worth in USD.',
  },
  estimatedAnnualIncomeRequired: {
    id: 'userMenu.estimatedAnnualIncomeRequired',
    defaultMessage: 'Please enter your estimated annual income in USD.',
  },
  estimatedInvestmentAmountRequired: {
    id: 'userMenu.estimatedInvestmentAmountRequired',
    defaultMessage: 'Please enter your estimated investment amount in USD.',
  },
  sourceOfFundsRequired: {
    id: 'userMenu.sourceOfFundsRequired',
    defaultMessage: 'Please specify the source of your funds.',
  },
  expectedOriginOfFundsRequired: {
    id: 'userMenu.expectedOriginOfFundsRequired',
    defaultMessage:
      'Please specify the expected origin of incoming funds to the trading account.',
  },
  passwordsMustMatch: {
    id: 'userMenu.passwordsMustMatch',
    defaultMessage: 'The confirmation password must match the new password',
  },
  confirmPasswordRequired: {
    id: 'userMenu.confirmPasswordRequired',
    defaultMessage: 'Please confirm your new password.',
  },
  jointAccountSubHeader: {
    id: 'userMenu.jointAccountSubHeader',
    defaultMessage: 'Enter the information below for second account holder',
  },
  edit: {
    id: 'userMenu.edit',
    defaultMessage: 'Edit',
  },
  view: {
    id: 'userMenu.view',
    defaultMessage: 'View',
  },
  select: {
    id: 'userMenu.select',
    defaultMessage: 'Select',
  },
  submitBtn: {
    id: 'userMenu.submitBtn',
    defaultMessage: 'Update',
  },
});
