import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  selectUserType: {
    id: 'selectUserType',
    defaultMessage: 'Select User Type',
  },
  submit: {
    id: 'submit',
    defaultMessage: 'Submit',
  },
  ok: {
    id: 'ok',
    defaultMessage: 'OK',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Confirm',
  },
  selectPaymentMethod: {
    id: 'selectPaymentMethod',
    defaultMessage: 'Please select a payment method.',
  },
  depositedInTo: {
    id: 'depositedInTo',
    defaultMessage: 'Target Account:',
  },
  paymentReference: {
    id: 'paymentReference',
    defaultMessage: 'Deposit ID:',
  },
  localAmount: {
    id: 'localAmount',
    defaultMessage: 'Requested Amount:',
  },
  tradingAmount: {
    id: 'tradingAmount',
    defaultMessage: 'Estimated Amount:',
  },
  paymentMethod: {
    id: 'paymentMethod',
    defaultMessage: 'Payment Method:',
  },
  withdrawalFrom: {
    id: 'withdrawalFrom',
    defaultMessage: 'Withdrawal from:',
  },
  withdrawalID: {
    id: 'withdrawalID',
    defaultMessage: 'Withdrawal ID:',
  },
  withdrawalTo: {
    id: 'withdrawalTo',
    defaultMessage: 'Withdrawal To:',
  },
  withdrawalAmount: {
    id: 'withdrawalAmount',
    defaultMessage: 'Withdrawal Amount:',
  },
  status: {
    id: 'status',
    defaultMessage: 'Status:',
  },
  pending: {
    id: 'pending',
    defaultMessage: 'Pending',
  },
  select: {
    id: 'select',
    defaultMessage: 'Select',
  },
});
