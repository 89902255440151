import CssBaseline from '@mui/material/CssBaseline';
import Box from 'v2s-ui-core/app/components/Box';
import { isMobile } from 'v2s-ui-core/app/utils';
import DynamicBreadcrumbs from '~/components/BreadCrumb/DynamicBraedCrumb';
import { breadCrumbRoutes } from '~/components/BreadCrumb/breadcrumbRoutes';
import { SelectUserTypeDialog } from '~/components/SelectUserTypeDialog';
import { useUserAuth } from '../Auth/AuthProvider';
import Appbar from './components/Appbar';
import Sidebar from './components/Sidebar';
import MobileSidebar from './components/Sidebar/MobileSidebar';
import { DRAWER_WIDTH } from './components/Sidebar/constants';
import { StyledProtectedLayout } from './styles';
import { useLayout } from './useLayout';

type TLayoutProps = React.PropsWithChildren;

const ProtectedLayout = (props: TLayoutProps) => {
  const { isSidebarOpen, setIsSidebarOpen } = useLayout();

  const { auth } = useUserAuth();
  return (
    <StyledProtectedLayout>
      <CssBaseline />
      <Appbar open={isSidebarOpen} onSidebarToggle={setIsSidebarOpen} />
      <Box className='content'>
        {isMobile() ? (
          <MobileSidebar
            open={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        ) : (
          <Sidebar open={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
        )}{' '}
        <Box
          className='content-body'
          sx={{
            '@media (max-width: 600px)': {
              marginLeft: 0,
            },
            marginLeft: isSidebarOpen ? `${DRAWER_WIDTH}px` : 8,
            // height: '100vh',
            // minHeight: '100vh',
            // maxHeight: '100vh',
          }}
        >
          <DynamicBreadcrumbs routes={breadCrumbRoutes} />
          {props.children}
        </Box>
        {auth?.user_type === null && <SelectUserTypeDialog />}
      </Box>
    </StyledProtectedLayout>
  );
};

export default ProtectedLayout;
